<template>
  <a-button
    v-bind="$attrs"
    :class="btnClass"
    :loading="loading"
    :disabled="disabled"
  >
    <!-- This icon slot is a fallback for images and
        should not be used when the icon is a FontAwesomeIcon -->
    <div v-if="$slots.icon" class="flex items-center justify-center">
      <slot name="icon" />
    </div>
    <div class="flex items-center whitespace-nowrap">
      <FontAwesomeIcon
        v-if="leftIcon"
        class="c-bitts-btn-icon c-bitts-btn__left-icon"
        :icon="leftIcon"
        :style="{ height: `${edgeIconSize}px`, width: `${edgeIconSize}px` }"
        :class="[loading ? 'opacity-0' : '', leftIconClasses]"
      />
      <template v-if="text">
        <span class="c-bitts-btn__text" :class="{ 'opacity-0': loading }">
          {{ text }}
        </span>
      </template>
      <FontAwesomeIcon
        v-else-if="centerIcon"
        class="c-bitts-btn-icon c-bitts-btn__center-icon"
        :icon="centerIcon"
        :style="{ height: `${centerIconSize}px`, width: `${centerIconSize}px` }"
        :class="[loading ? 'opacity-0' : '', centerIconClasses]"
      />
      <slot name="rightIcon">
        <FontAwesomeIcon
          v-if="rightIcon"
          class="c-bitts-btn-icon c-bitts-btn__right-icon"
          :icon="rightIcon"
          :style="{ height: `${edgeIconSize}px`, width: `${edgeIconSize}px` }"
          :class="[loading ? 'opacity-0' : '', rightIconClasses]"
        />
      </slot>
      <!-- Like the "icon" slot, this should not be used whenever possible,
      so that our buttons remain consistent -->
      <div v-if="$slots['extra-content']">
        <slot name="extra-content" />
      </div>
    </div>
    <div
      v-if="variant === 'outline' || variant === 'ghost'"
      class="extra-background"
      :class="`extra-background--${size}`"
    />
  </a-button>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Button as AButton } from 'ant-design-vue';
import { computed } from 'vue';

import { BittsClasses, BittsIcon, BittsSize } from '../types';

export type BittsButtonSize = Exclude<BittsSize, 'x-large' | 'tiny'>;
export type BittsButtonType =
  | 'upsell'
  | 'primary'
  | 'neutral'
  | 'danger'
  | 'white';
export type BittsButtonVariant = 'fill' | 'outline' | 'ghost';
</script>
<script setup lang="ts">
export type Props = {
  text?: string;
  type?: BittsButtonType;
  size?: BittsButtonSize;
  disabled?: boolean;
  variant?: BittsButtonVariant;
  loading?: boolean;
  centerIcon?: BittsIcon;
  centerIconClasses?: BittsClasses;
  leftIcon?: BittsIcon;
  leftIconClasses?: BittsClasses;
  rightIcon?: BittsIcon;
  rightIconClasses?: BittsClasses;
  active?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  text: '',
  type: 'primary',
  size: 'medium',
  disabled: false,
  variant: 'fill',
  loading: false,
  active: false,
  centerIcon: null,
  centerIconClasses: null,
  leftIcon: null,
  leftIconClasses: null,
  rightIcon: null,
  rightIconClasses: null,
});

const SIZE_TO_CLASS_MAP = {
  'x-small': 'c-bitts-btn-xs',
  small: 'c-bitts-btn-small',
  medium: 'c-bitts-btn-medium',
  large: 'c-bitts-btn-large',
};

const TYPE_TO_CLASS_MAP = {
  upsell: 'c-bitts-btn-upsell',
  primary: 'c-bitts-btn-primary',
  neutral: 'c-bitts-btn-neutral',
  danger: 'c-bitts-btn-danger',
  white: 'c-bitts-btn-white',
};

const edgeIconSize = computed(() => {
  if (props.type === 'upsell') return 16;
  return ['large', 'medium', 'x-small'].includes(props.size) ? 12 : 10;
});
const centerIconSize = computed(() => {
  return ['large', 'medium'].includes(props.size) ? 16 : 12;
});
const btnClass = computed(() => {
  /* Construct size + spacing from size + centerIcon props,
      construct colors + font from type and variant props,
      construct focus outline on type prop */

  const iconFamily = props.centerIcon ? 'c-bitts-btn-icon' : '';

  const baseSize = SIZE_TO_CLASS_MAP[props.size];
  const sizeWithIcon = props.centerIcon
    ? `${SIZE_TO_CLASS_MAP[props.size]}--icon`
    : '';

  const baseType = TYPE_TO_CLASS_MAP[props.type];
  const type = `${baseType}--${props.variant}`;

  const outlineType = `${props.type === 'white' ? 'white' : 'blue'}-outline-on-focus`;

  const active = props.active ? 'active' : '';
  const variant = `c-bitts-btn--${props.variant}`;

  return [
    'c-bitts-btn',
    iconFamily,
    baseSize,
    sizeWithIcon,
    type,
    baseType,
    outlineType,
    active,
    variant,
  ];
});
</script>

<style lang="pcss" scoped>
/* Base */
.c-bitts-btn {
  outline: none; /* We add our own outlines */
  @apply relative text-base flex justify-center items-center leading-6 cursor-pointer;
  font-family: inherit;
}

.c-bitts-btn:disabled {
  @apply opacity-50 bg-blend-multiply cursor-not-allowed;
}

.c-bitts-btn__text {
  @apply font-bold;
}
/* Sizes */
.c-bitts-btn-large {
  @apply py-12 px-24 rounded-12 h-auto;
}

.c-bitts-btn-medium {
  @apply py-8 px-16 rounded-8 h-auto;
}

.c-bitts-btn-small,
.c-bitts-btn-xs {
  @apply text-sm leading-6 h-auto;
}

.c-bitts-btn-small {
  @apply py-4 px-12 rounded-6;
}

.c-bitts-btn-xs {
  @apply py-0 px-8;
  border-radius: 4px;
}

.c-bitts-btn-large,
.c-bitts-btn-medium {
  font-size: 16px;
  .c-bitts-btn__text {
    @apply mb-[-2px];
  }
}

/* Icon Only sizes */
.c-bitts-btn-large--icon {
  @apply w-48 h-48 rounded-12;
}

.c-bitts-btn-medium--icon {
  @apply w-40 h-40 rounded-8;
}

.c-bitts-btn-small--icon {
  @apply w-32 h-32 rounded-6;
}

.c-bitts-btn-xs--icon {
  @apply w-24 h-24 rounded-4 p-0;
}

/* L/R Icon spacing */
.c-bitts-btn-medium .c-bitts-btn__left-icon,
.c-bitts-btn-large .c-bitts-btn__left-icon {
  @apply pr-8 h-full;
}

.c-bitts-btn-medium .c-bitts-btn__right-icon,
.c-bitts-btn-large .c-bitts-btn__right-icon {
  @apply pl-6;
}

.c-bitts-btn-small .c-bitts-btn__left-icon,
.c-bitts-btn-xs .c-bitts-btn__left-icon {
  @apply pr-4 h-full;
}

.c-bitts-btn-small .c-bitts-btn__right-icon,
.c-bitts-btn-xs .c-bitts-btn__right-icon {
  @apply pl-4 h-full;
}

/* The .extra-background is used to provide an additional radial gradient background to outline and ghost buttons */
.c-bitts-btn-icon,
.c-bitts-btn__text {
  z-index: 1;
}
.extra-background {
  z-index: 0;
  @apply absolute w-full h-full opacity-0;
  &.extra-background--large {
    @apply rounded-12;
  }
  &.extra-background--medium {
    @apply rounded-8;
  }
  &.extra-background--small {
    @apply rounded-6;
  }
  &.extra-background--x-small {
    @apply rounded-4;
  }
}

/* COLORS */
.c-bitts-btn {
  @apply border-none shadow-none;
}

.c-bitts-btn--ghost {
  @apply bg-transparent; /* This is overridden per button on hover, etc */
}

.c-bitts-btn--outline,
.c-bitts-btn--ghost,
.c-bitts-btn--fill {
  @apply transition-none;
  &:not(.active) {
    /* Primary button colors */
    &.c-bitts-btn-primary {
      .extra-background {
        background: radial-gradient(
          125% 125% at 50.13% 100%,
          theme(colors.brand-blue) 2.08%,
          theme(colors.brand-navy) 90.11%
        );
      }
      &.c-bitts-btn--fill {
        @apply text-white;
        background: radial-gradient(
          125% 125% at 50.13% 100%,
          theme(colors.brand-blue) 2.08%,
          theme(colors.brand-navy) 80.11%
        );
        &:hover:not([disabled]) {
          @apply text-white bg-white bg-blend-overlay bg-opacity-[.16];
        }
        &:active:not([disabled]) {
          @apply bg-black bg-blend-overlay bg-opacity-[.32];
        }
      }

      &.c-bitts-btn--ghost,
      &.c-bitts-btn--outline {
        &:hover:not([disabled]) {
          .extra-background {
            @apply opacity-10;
          }
        }
        &:active:not([disabled]) {
          .extra-background {
            @apply opacity-20;
          }
        }
        &:focus-visible:not([disabled]):not(:active) {
          .extra-background {
            @apply opacity-10 border-2 border-transparent;
            box-shadow: theme(colors.white) inset 0px 0px 0px 2px;
          }
        }
        .c-bitts-btn-icon {
          @apply text-primary-accent;
          &:hover {
            @apply text-primary-accent;
          }
        }
        &:disabled {
          @apply bg-transparent;
        }
        .c-bitts-btn__text {
          background: radial-gradient(
            125% 125% at 50.13% 100%,
            theme(colors.brand-blue) 2.08%,
            theme(colors.brand-navy) 80.11%
          );
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }
      }
    }

    /* Neutral button colors */
    &.c-bitts-btn-neutral {
      @apply text-neutral-text-button;
      .extra-background {
        background: radial-gradient(
          125% 125% at 50.13% 100%,
          theme(colors.neutral.background-weak) 2.08%,
          theme(colors.neutral.background-disabled) 90.11%
        );
      }
      &.c-bitts-btn--fill {
        background: radial-gradient(
          125% 125% at 50.13% 100%,
          theme(colors.neutral.background-weak) 2.08%,
          theme(colors.neutral.background-disabled) 80.11%
        );
        &:hover:not([disabled]) {
          @apply bg-white bg-blend-overlay bg-opacity-[.32];
        }
        &:active:not([disabled]) {
          @apply bg-black bg-blend-overlay bg-opacity-[.08];
        }
      }

      &.c-bitts-btn--outline {
        .extra-background {
          @apply border border-neutral-accent;
        }
      }

      &.c-bitts-btn--ghost,
      &.c-bitts-btn--outline {
        &:focus-visible:not([disabled]):not(:active),
        &:hover:not([disabled]):not(:active) {
          .extra-background {
            @apply opacity-30;
          }
        }

        &:active:not([disabled]) {
          .extra-background {
            @apply opacity-60;
          }
        }

        &:focus-visible:not([disabled]):not(:active) {
          .extra-background {
            @apply opacity-30;
            box-shadow: theme(colors.white) inset 0px 0px 0px 2px;
          }
        }
      }

      .c-bitts-btn-icon {
        @apply text-neutral-text-button;
        &:hover {
          @apply text-neutral-text-button;
        }
      }
    }

    /* Danger button colors */
    &.c-bitts-btn-danger {
      .extra-background {
        background: radial-gradient(
          125% 125% at 50.13% 100%,
          theme(colors.danger.background-medium) 2.08%,
          theme(colors.danger.text) 90.11%
        );
      }
      &.c-bitts-btn--fill {
        @apply text-white;
        background: radial-gradient(
          125% 125% at 50.13% 100%,
          theme(colors.danger.background-medium) 2.08%,
          theme(colors.danger.text) 80.11%
        );
        &:hover:not([disabled]) {
          @apply text-white bg-white bg-blend-overlay bg-opacity-[.08];
        }
        &:active:not([disabled]) {
          @apply bg-black bg-blend-overlay bg-opacity-[.16];
        }
      }

      &.c-bitts-btn--ghost,
      &.c-bitts-btn--outline {
        &:disabled {
          @apply bg-transparent;
        }
        .c-bitts-btn__text {
          background: radial-gradient(
            125% 125% at 50.13% 100%,
            theme(colors.danger.background-medium) 2.08%,
            theme(colors.danger.text) 80.11%
          );
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }
        .extra-background {
          @apply border border-neutral-accent;
        }

        &:hover:not([disabled]):not(:active) {
          .extra-background {
            @apply opacity-10;
          }
        }

        &:active:not([disabled]) {
          .extra-background {
            @apply opacity-20;
          }
        }

        &:focus-visible:not([disabled]):not(:active) {
          .extra-background {
            @apply opacity-10 border-2 border-transparent;
            box-shadow: theme(colors.white) inset 0px 0px 0px 2px;
          }
        }
        .c-bitts-btn-icon {
          @apply text-danger-text-button;
          &:hover {
            @apply text-danger-text-button;
          }
        }
      }
    }

    /* White button colors */
    &.c-bitts-btn-white {
      @apply text-white;
      &.c-bitts-btn--fill {
        background: radial-gradient(
          125% 125% at 50.13% 100%,
          theme(colors.white / 0.125) 2.08%,
          theme(colors.white / 0.376) 80.11%
        );
        &:hover:not([disabled]) {
          @apply text-white bg-white bg-blend-overlay bg-opacity-[.08];
        }
        &:focus-visible:not([disabled]):not(:active) {
          @apply bg-white bg-blend-overlay bg-opacity-[.16];
        }
        &:active:not([disabled]) {
          @apply bg-black bg-blend-overlay bg-opacity-[.16];
        }
      }

      &.c-bitts-btn--ghost,
      &.c-bitts-btn--outline {
        background: none;
        &:hover:not([disabled]) {
          background: radial-gradient(
            125% 125% at 50.13% 100%,
            theme(colors.white / 0.125) 2.08%,
            theme(colors.white / 0.376) 80.11%
          );
        }
        &:focus-visible:not([disabled]):not(:active) {
          @apply bg-white bg-blend-overlay bg-opacity-[.32];
        }
        &:active:not([disabled]) {
          @apply bg-black bg-blend-overlay bg-opacity-[.16];
        }
      }

      &.c-bitts-btn-white--outline:not(:focus-visible) {
        box-shadow: white inset 0px 0px 0px 1px;
      }
    }
  }
}

/* We cannot use a border in this case, because it would
* cause layout shift and the outline/ghost buttons would
* be a different size with the same content. Instead we
* are using an interior box shadow and changing it's color
* and properties on hover/pressed states.
*/
.c-bitts-btn-primary--outline:not(:focus-visible):not(.active),
.c-bitts-btn-danger--outline:not(:focus-visible):not(.active),
.c-bitts-btn-neutral--outline:not(:focus-visible):not(.active) {
  @apply border-none text-neutral-text-button;
  box-shadow: theme(colors.neutral.accent) inset 0px 0px 0px 1px;
}

/* The active prop causes the button to be styled/colored styled differently regardless of "type,"
e.g. danger, neutral, and so forth are overridden here */
.active {
  &.c-bitts-btn--outline,
  &.c-bitts-btn--fill {
    @apply bg-secondary-background-weak text-secondary-text !important;
  }

  &.c-bitts-btn--outline {
    @apply bg-secondary-background-weak text-secondary-text outline outline-1 outline-neutral-accent !important;
  }

  &.c-bitts-btn--ghost {
    @apply text-secondary-text !important;
  }
  &.extra-background {
    @apply hidden;
  }
}

/* This is the only color that does not respect different
variants (it's always the same) */
.c-bitts-btn-upsell--ghost,
.c-bitts-btn-upsell--outline,
.c-bitts-btn-upsell {
  @apply text-white border-none;
  background: radial-gradient(
    125% 125% at 50.13% 100%,
    theme(colors.upsell.background-medium) 2.08%,
    theme(colors.violet.700) 90.11%
  );
  &:not([disabled]):focus-visible,
  &:not([disabled]):hover {
    @apply bg-white bg-blend-overlay bg-opacity-[.10] text-white;
  }
  &:not([disabled]):active {
    @apply bg-black bg-blend-overlay bg-opacity-[.30];
  }
}

/* Focused states */
.c-bitts-btn-xs:focus-visible,
.c-bitts-btn-small:focus-visible,
.c-bitts-btn-medium:focus-visible,
.c-bitts-btn-large:focus-visible {
  @apply outline-none;
}

.c-bitts-btn-large.blue-outline-on-focus:not([disabled]):focus-visible,
.c-bitts-btn-medium.blue-outline-on-focus:not([disabled]):focus-visible {
  box-shadow:
    inset 0px 0px 0px 2px theme('colors.neutral.border-focus'),
    inset 0px 0px 0px 4px white;
}

.c-bitts-btn-small.blue-outline-on-focus:not([disabled]):focus-visible,
.c-bitts-btn-xs.blue-outline-on-focus:not([disabled]):focus-visible {
  box-shadow:
    inset 0px 0px 0px 2px theme('colors.neutral.border-focus'),
    inset 0px 0px 0px 3px white;
}

.c-bitts-btn-large.white-outline-on-focus:not([disabled]):focus-visible,
.c-bitts-btn-medium.white-outline-on-focus:not([disabled]):focus-visible {
  box-shadow:
    inset 0px 0px 0px 2px white,
    inset 0px 0px 0px 4px white;
}

.c-bitts-btn-small.white-outline-on-focus:not([disabled]):focus-visible,
.c-bitts-btn-xs.white-outline-on-focus:not([disabled]):focus-visible {
  box-shadow:
    inset 0px 0px 0px 2px white,
    inset 0px 0px 0px 3px white;
}
</style>
<style lang="pcss">
/* Loading */
.c-bitts-btn.ant-btn .ant-btn-loading-icon {
  transition: none !important;
}

.c-bitts-btn .anticon-loading {
  position: absolute;
  @apply text-white;
}

.c-bitts-btn-large {
  .anticon-loading {
    top: 16px;
    left: calc(50% - 10px);
  }
}

.c-bitts-btn-medium {
  .anticon-loading {
    top: 12px;
    left: calc(50% - 10px);
  }
}

.c-bitts-btn-small {
  .anticon-loading {
    top: 10px;
    left: calc(50% - 6px);
  }
}

.c-bitts-btn-xs {
  .anticon-loading {
    top: 6px;
    left: calc(50% - 4px);
  }
}

.c-bitts-btn-neutral .anticon-loading,
.c-bitts-btn-neutral--outline .anticon-loading,
.c-bitts-btn-neutral--ghost .anticon-loading {
  @apply text-neutral-text-button;
}

.c-bitts-btn-danger--outline .anticon-loading,
.c-bitts-btn-danger--ghost .anticon-loading {
  @apply text-danger-text-button;
}

.c-bitts-btn-primary--outline .anticon-loading,
.c-bitts-btn-primary--ghost .anticon-loading {
  @apply text-primary-text-button;
}

@-webkit-keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

.c-bitts-btn .anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
.c-bitts-btn .anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
</style>
